export default [
    {
      path: "/login",
      component: () => import("@/views/authentication/Login"),
    },
    {
      path: "/access-denied",
      component: () => import("@/views/access-denied/AccessDenied"),
    },
    {
      path: "/recovery-password/:id",
      component: () => import("@/views/recovery-password/RecoveryPassword"),
    },
    {
      path: "/email-verification/:id",
      component: () => import("@/views/email-verification/EmailVerification"),
    },
  ];
  