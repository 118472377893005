export default [
    {
        path: 'loans-affiliates',
        component: () => import('@/layouts/blank-layout/Blanklayout'),
        children: [
            {
                name: 'Partners',
                path: '',
                component: () => import('@/views/loans/LoanPartners'),
                meta: {
                    requiresAuth: true,
                    requiredPermissions: ['/Partners-GetList'],
                },
            },
            {
                name: 'Calculator',
                path: 'calculator',
                component: () => import('@/views/loans/LoanCreate'),
                meta: {
                    requiresAuth: true,
                    requiredPermissions: ['/Partners-GetList'],
                },
            },
        ],
    },
];
