export default [
    {
        name: 'Home',
        path: '/home',
        component: () => import('@/views/Home'),
        meta: {
            requiresAuth: true,
            requiredPermissions: [],
        },
    },
    {
        name: 'Dashboard',
        path: '/dashboard',
        component: () => import('@/views/dashboard/Dashboard'),
        meta: {
            requiresAuth: true,
            requiredPermissions: ['/Dashboards'],
        },
    },
];
