import axios from 'axios';
const url = 'loans/Api/FinancialInformation/';

export default {
    namespaced: true,
    state() {
        return {};
    },
    getters: {},
    mutations: {},
    actions: {
        async get_incomesByLoanApplicationId(_, loanApplicationId) {
            try {
                const response = await axios.get(`${url}Incomes/GetByLoanApplicationId/${loanApplicationId}`);
                return response?.data?.dataResult ?? [];
            } catch (error) {
                this._vm.$bvToast.toast('Error fetching incomes', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async add_income({ rootGetters }, payload) {
            try {
                const response = await axios.post(`${url}Incomes/Add`, {
                    ...payload,
                    createdBy: rootGetters['auth/getUser'].Id,
                });
                this._vm.$bvToast.toast('Income added successfully', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });

                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error adding income', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
        async update_income({ rootGetters }, payload) {
            try {
                const response = await axios.put(`${url}Incomes/Update`, {
                    ...payload,
                    modifiedBy: rootGetters['auth/getUser'].Id,
                });

                this._vm.$bvToast.toast('Income updated successfully', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });

                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error updating income', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
        async delete_income(_, id) {
            try {
                const response = await axios.delete(`${url}Incomes/Remove/${id}`);

                this._vm.$bvToast.toast('Income deleted successfully', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });

                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error deleting income', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async get_incomesTypes() {
            try {
                const response = await axios.get(`${url}Incomes/GetTypes`);
                return response?.data?.dataResult ?? [];
            } catch (error) {
                this._vm.$bvToast.toast('Error fetching income types', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async get_expensesByLoanApplicationId(_, loanApplicationId) {
            try {
                const response = await axios.get(`${url}Expenses/GetByLoanApplicationId/${loanApplicationId}`);
                return response?.data?.dataResult ?? [];
            } catch (error) {
                this._vm.$bvToast.toast('Error fetching expenses', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async add_expense({ rootGetters }, payload) {
            try {
                const response = await axios.post(`${url}Expenses/Add`, {
                    ...payload,
                    createdBy: rootGetters['auth/getUser'].Id,
                });
                this._vm.$bvToast.toast('Expense added successfully', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });

                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error adding expense', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
        async update_expense({ rootGetters }, payload) {
            try {
                const response = await axios.put(`${url}Expenses/Update`, {
                    ...payload,
                    modifiedBy: rootGetters['auth/getUser'].Id,
                });

                this._vm.$bvToast.toast('Expense updated successfully', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });

                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error updating expense', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async delete_expense(_, id) {
            try {
                const response = await axios.delete(`${url}Expenses/Remove/${id}`);
                this._vm.$bvToast.toast('Expense deleted successfully', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });

                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error deleting expense', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async get_expensesTypes() {
            try {
                const response = await axios.get(`${url}Expenses/GetTypes`);
                return response?.data?.dataResult ?? [];
            } catch (error) {
                this._vm.$bvToast.toast('Error fetching expense types', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
    },
};
