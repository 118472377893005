import axios from 'axios';
const url = 'loans/Api/CompanyLoansApplications/';

export default {
    namespaced: true,
    state() {
        return {
            applications: [],
            totalApplications: 0,
            loanApp: null,
        };
    },
    getters: {
        getApplications(state) {
            return state.applications;
        },
        getTotalApplications(state) {
            return state.totalApplications;
        },
        getLoan(state) {
            return state.loanApp;
        },
    },
    mutations: {
        SET_APPLICATIONS(state, applications) {
            state.applications = applications;
        },
        SET_TOTAL_APPLICATIONS(state, totalApplications) {
            state.totalApplications = totalApplications;
        },
        SET_LOAN_APPLICATION(state, loan) {
            state.loanApp = loan;
        },
    },
    actions: {
        async get_allBuilders({ rootGetters }, payload) {
            try {
                const response = await axios.get(
                    `${url}/GetAll?Page=${payload.skip}&RecordsPerPage=${payload.take}&CompanyId=${rootGetters['auth/getUser'].CompanyId}`,
                );
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting applications', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
        async get_companyInformation({ rootGetters }) {
            try {
                const response = await axios.get(
                    `${url}GetCompanyInformation/${rootGetters['auth/getUser'].CompanyId}`,
                );
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting company information', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
        async get_monthsByLoanType({ rootGetters }, payload) {
            try {
                const response = await axios.get(
                    `${url}GetMonthsByLoanType?Amount=${payload.amount}&LoanTypeId=1&CompanyId=${rootGetters['auth/getUser'].CompanyId}`,
                );
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting months', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
        async get_monthlyPayment({ rootGetters }, payload) {
            try {
                const response = await axios.get(
                    `${url}GetMonthtlyPayment?Amount=${payload.amount}&Months=${payload.months}&LoanTypeId=1&CompanyId=${rootGetters['auth/getUser'].CompanyId}`,
                );
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting monthly payment', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async get_companyLoanRange({ rootGetters }) {
            try {
                const response = await axios.get(
                    `${url}GetLoanRange?LoanTypeId=1&CompanyId=${rootGetters['auth/getUser'].CompanyId}`,
                );
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting months', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async add_builders({ rootGetters }, applicationData) {
            try {
                const formData = new FormData();
                formData.append('LoanTypeId', 1);
                formData.append('Amount', applicationData.amount);
                formData.append('MonthPeriod', applicationData.periodInMonths);
                formData.append('MonthPayment', applicationData.monthlyPayment);
                formData.append('FirstName', applicationData.firstName);
                formData.append('LastName', applicationData.lastName);
                formData.append('Email', applicationData.email);
                formData.append('Telephone', applicationData.phone);
                formData.append('Address', applicationData.address);
                formData.append('Employeer', applicationData.employeerName);
                formData.append('DocumentId', applicationData.id);
                formData.append('Payslip', applicationData.payslip1);
                formData.append('Others', applicationData.other);
                formData.append('Quote', applicationData.quote);
                formData.append('CompanyId', rootGetters['auth/getUser'].CompanyId);

                const response = await axios.post(`${url}Add`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                this._vm.$bvToast.toast('Loan request has been sent to Total.', {
                    title: 'Upload complete.',
                    variant: 'success',
                    solid: true,
                });
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error saving data', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
    },
};
