export default [
    {
        path: 'products',
        redirect: { name: 'Products Rules' },
        component: () => import('@/layouts/ApplicationLayout'),
        children: [
            {
                name: 'Products Rules',
                path: 'rules',
                component: () => import('@/views/products/ProductRules'),
                meta: {
                    requiresAuth: true,
                    requiredPermissions: ['/Rules'],
                },
            },
            {
                name: 'Types',
                path: 'types',
                component: () => import('@/views/products/ProductTypes'),
                meta: {
                    requiresAuth: true,
                    requiredPermissions: ['/LoanTypes'],
                },
            },
        ],
    },
];
